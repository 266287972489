import React, { useEffect, useMemo, useRef } from 'react'
import formatDistance from 'date-fns/formatDistance'
import cn from 'classnames'

import { ProductDetailsLink } from 'components/product-details-link'
import { Rating } from 'components/rating'

import { ReadMoreCta } from 'page-modules/product/read-more-cta'
import useIsContentExpandable from 'page-modules/product/useIsContentExpandable'

import useCustomTranslation from 'lib/hooks/useTranslation'

import { dateStringToLocalDate, removeHtmlTag } from 'lib/utils'

import ApostropheImg from 'brand-assets/illustrations/apostrophe.svg'
import PelagoBlackIcon from 'brand-assets/illustrations/logo/pelago-black.svg'
import ViatorLogoIcon from 'brand-assets/illustrations/logo/viator-logo.svg'

import s from './styles.module.scss'

type ReviewProps = {
  item?: Review
  dateLocale?: Locale
  variant?: 'pagination' | 'home'
  bringIntoView?: boolean
  showCompanyLogo?: boolean
  onExpandClick?: () => void
  onProductNameClick?: (args0: Record<string, any>) => void
}

const LOGOS: Record<ReviewProvider, any> = {
  PELAGO: PelagoBlackIcon,
  VIATOR: ViatorLogoIcon,
  BMG: null,
  GLOBALTIX: null,
  KKDAY: null,
  REZDY: null,
  TIQETS: null,
}

const ReviewItem = ({
  item,
  dateLocale,
  variant,
  bringIntoView,
  showCompanyLogo,
  onExpandClick,
  onProductNameClick,
}: ReviewProps) => {
  const contentRef = useRef<HTMLParagraphElement>(null)
  const { t } = useCustomTranslation(['product'])
  const { isExpandable, isExpanded, setIsExpanded } = useIsContentExpandable(contentRef)
  const {
    travellerType,
    reviewDate,
    rating,
    firstName,
    comment,
    title,
    productName,
    productId,
    productUri,
    externalSource,
  } = item || {}
  const showReadMoreCta = ['pagination', 'home'].indexOf(variant || '') === -1 && isExpandable
  const isThirdPartyReviews = externalSource !== null
  const CompanyLogo = externalSource ? LOGOS[externalSource] : LOGOS.PELAGO

  useEffect(() => {
    if (!bringIntoView) return
    setTimeout(() => {
      if (contentRef?.current) {
        contentRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }, 300)
  }, [bringIntoView])

  const commentDate = useMemo(() => {
    if (variant === 'home') return

    const today = new Date()
    if (!reviewDate || !dateLocale?.code) return null

    return formatDistance(dateStringToLocalDate(reviewDate), today, {
      addSuffix: true,
      locale: dateLocale,
    })
  }, [dateLocale, reviewDate, variant])

  const displayComment = useMemo(() => {
    return removeHtmlTag(comment)
  }, [comment])

  return (
    <div className={cn(s.box, { [s.pagination]: variant === 'pagination' })}>
      <div className={s.background}>
        <div className={s.body}>
          <Rating value={rating || 0} isReadOnly />
          <p
            className={cn(s.comment, {
              [s.expanded]: isExpanded || variant === 'pagination',
              [s.expandable]: isExpandable,
              [s.ellipsize]: variant === 'home',
            })}
            ref={contentRef}
          >
            {title && (
              <>
                {title}
                <span className={s.separator} />
              </>
            )}
            {displayComment}
          </p>
          {showReadMoreCta && (
            <ReadMoreCta
              isExpanded={isExpanded}
              onClick={() => {
                if (onExpandClick) onExpandClick?.()
                else setIsExpanded(!isExpanded)
              }}
              size="small"
            />
          )}
        </div>
        <div className={s.footer}>
          <div className={s.nameAndType}>
            <span>{firstName}</span>
            {travellerType && (
              <>
                <span className={s.separator}>.</span>
                <span>{travellerType}</span>
              </>
            )}
          </div>
          <div className={s.flex}>
            <div className={s.dateOrProductName}>
              {variant === 'home' ? (
                <ProductDetailsLink
                  productId={productId}
                  productUri={productUri}
                  onClick={() => {
                    onProductNameClick?.({ id: productId })
                  }}
                >
                  {productName}
                </ProductDetailsLink>
              ) : (
                commentDate
              )}
            </div>
            {showCompanyLogo && (
              <div className={s.logoContainer}>
                {isThirdPartyReviews && !!CompanyLogo && (
                  <span className="text-xs">{t('collectedBy', { ns: 'product' })}</span>
                )}
                <CompanyLogo />
              </div>
            )}
          </div>
        </div>
        {variant !== 'pagination' && (
          <div className={s.apostrophe}>
            <ApostropheImg className="mr-1" />
            <ApostropheImg />
          </div>
        )}
      </div>
    </div>
  )
}

export { ReviewItem }
